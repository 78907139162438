const validationMessage = {
    reqTempleSelect:"Please select temple",
    reqTempleNameEn:"Please enter temple name in English",
    reqYatraName:"Please select/enter yatra name",
    reqPadavName:"Please select/enter padav name",
    reqSequenceNumber:"Please enter sequence number",
    reqTempleDescEn:"Please enter temple Description in English",
    reqTempleLongitude:"Please enter temple longitude",
    reqTempleLatitude:"Please enter temple latitude",
    reqUsername:'Please enter the valid username/email',
    reqPassword:'Please enter the password',
    reqConfirmPassword:'Please enter the confirm password',
    matchPassword:'Confirm password and password should be match',
    reqGender:'Please select gender',
    reqFirstName:'Please enter firstname',
    reqMobile:'Please enter the valid mobile number',
    reqTnC:'Please accept the term and conditions.',
    reqYatraEn:"Please enter name in English",
    reqPadavEn:"Please enter Padav name in English",
    reqNewsUpdateEn:"Please enter name in English",
    reqFeedbackName:"Please enter the name",
    reqFeedbackContactNumber:"Please enter the Contact Number",
    reqFeedbackEmailId:"Please enter the Email Id",
    reqFeedbackComment:"Please enter the Feedback Comment"
}
export { validationMessage }